// @flow

import React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { InputError, AutoComplete } from '../../components';
import { withStyles } from 'tss-react/mui';
import { Button, Typography, Grid } from '@mui/material';

import TextInputField from './TextInputField';

import { states } from './states';

import VerifyModal from './Modals/VerifyModal';
import QueryAutoComplete from './QueryAutoComplete';

import styles from './Styles/NewQuery.Style';

type Props = {
  classes: Object,
  preFilledFields: Object
};

const baseStyle = {
  flex: '0 1 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 0,
  borderRadius: 0,
  borderColor: '#eeeeee',
  margin: 14,
  backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: 300,
  overflow: 'hidden'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const queryFields = [
  {
    name: 'patient_first_name',
    label: 'Patient First Name',
    required: true,
    type: 'queryAutoComplete'
  },
  {
    name: 'patient_middle_name',
    label: 'Patient Middle Name',
    required: false,
    type: 'string'
  },
  {
    name: 'patient_last_name',
    label: 'Patient Last Name',
    required: true,
    type: 'string'
  },
  {
    name: 'patient_dob',
    label: 'Patient Date of Birth',
    required: true,
    type: 'string'
  },
  {
    name: 'patient_sex',
    label: 'Patient Sex',
    required: true,
    type: 'option',
    options: [
      { name: 'Female', value: 'F' },
      { name: 'Male', value: 'M' }
    ]
  },
  {
    name: 'patient_org_source_id',
    label: 'Source Org. Patient ID',
    required: false,
    type: 'string'
  },
  {
    name: 'patient_address',
    label: 'Patient Address',
    required: false,
    type: 'autocomplete'
  },
  {
    name: 'patient_city',
    label: 'Patient City',
    required: false,
    type: 'autocomplete',
    simple: true
  },
  {
    name: 'patient_state',
    label: 'Patient State',
    required: false,
    type: 'autocomplete',
    simple: true,
    customOptions: states
  },
  {
    name: 'patient_zip',
    label: 'Patient Zip',
    required: true,
    type: 'autocomplete',
    simple: true
  },
  {
    name: 'patient_phone',
    label: 'Patient Phone (XXX-XXX-XXXX)',
    required: false,
    type: 'string'
  },
  {
    name: 'provider_group_name',
    label: 'Provider Group Name (comma-separated if multiple)',
    required: false,
    type: 'string'
  },
  {
    name: 'icd_codes',
    label: 'Known ICD-10 Codes (comma separated)',
    required: false,
    type: 'string'
  },
  {
    name: 'batchName',
    label: 'Batch Name',
    required: false,
    type: 'string'
  }
];

const QueryForm = (props: Props) => {
  const { classes } = props;

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      outputs: []
    },
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const [acceptedFile, setAcceptedFile] = useState(null);

  const onDrop = useCallback((addedFile) => {
    console.log('[handleUploadTenantFile]', addedFile[0].size);
    setAcceptedFile(addedFile[0]);
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: false,
    onDrop,
    accept:
      /* eslint-disable max-len */
      'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip'
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [fieldData, setFieldData] = useState(null);

  const [outputs] = useState([]);

  const verifyFields = (data) => {
    if (!data) return;
    console.log('[verifyFields] data: ', data);

    setFieldData({ ...data });
  };

  const handleModalClose = () => {
    setFieldData(null);
  };

  useEffect(() => {
    const fields = getValues();
    console.log('[QueryForm.useEffect] fields: ', fields);
  }, [outputs, setValue]);

  /* eslint-disable react/prop-types */
  const renderQueryFields = (queryField) => {
    if (!queryField) return <div></div>;
    console.log('xox', queryField.type, queryField.label);
    return (
      <>
        {queryField.type === 'autocomplete' ? (
          <>
            <AutoComplete
              name={queryField.name}
              control={control}
              className={classes.txtField}
              label={queryField.label}
              required={queryField.required}
              simple={queryField.simple}
              customoptions={queryField.customOptions}
              setvalue={setValue}
            />
            {errors[queryField.name] && (
              <InputError text={errors[queryField.name].message} />
            )}
          </>
        ) : queryField.type === 'queryAutoComplete' ? (
          <>
            <QueryAutoComplete
              name={queryField.name}
              control={control}
              className={classes.txtField}
              label={queryField.label}
              required={queryField.required}
              setValue={setValue}
            />
            {errors[queryField.name] && (
              <InputError text={errors[queryField.name].message} />
            )}
          </>
        ) : (
          <>
            <TextInputField
              queryField={queryField}
              control={control}
              setValue={setValue}
            />
            {errors[queryField.name] && (
              <InputError text={errors[queryField.name].message} />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div>
        <form
          key="query"
          className={classes.formCtr}
          noValidate
          onSubmit={handleSubmit(verifyFields)}
        >
          {queryFields.map((queryField, idx) => (
            <div key={`qf-${idx}`}>{renderQueryFields(queryField)}</div>
          ))}
          <div className={classes.box}>
            <div className={classes.boxRow}>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />

                <div className={classes.innerbox}>
                  <Grid className={classes.center}>
                    <Typography className={classes.fileHeader} variant="h6">
                      {acceptedFile
                        ? acceptedFile.name
                        : 'Click here to attach a zip file'}
                    </Typography>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.verifyBtn}
          >
            Verify
          </Button>
        </form>
      </div>
      {fieldData ? (
        <VerifyModal
          reset={reset}
          fieldData={fieldData}
          handleClose={handleModalClose}
          queryFields={queryFields}
          acceptedFile={acceptedFile}
        />
      ) : null}
    </>
  );
};

export default withStyles(QueryForm, styles);
