import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

const serverURL = process.env.REACT_APP_API_ENDPOINT;

const getNewJWTToken = async () => {
  const currentSession = await Auth.currentSession();
  const tok = currentSession.getIdToken().getJwtToken();
  return tok;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}`,
    prepareHeaders: async (headers) => {
      const jwtToken = await getNewJWTToken();
      headers.set('Content-Type', 'application/json');
      headers.set('authorization', `Bearer ${jwtToken ? jwtToken : ''}`);
      if (process.env.REACT_APP_ENV === 'dev') {
        headers.set('x-mdp-email', 'test@email.com');
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => 'user',
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    getOrganizations: builder.query({
      query: () => 'organization/all',
      transformResponse: (response) => {
        const { organizations, currentOrganizationId } = JSON.parse(
          response.body
        );
        if (response.statusCode !== 200) throw new Error(response.body);
        const currentOrganization = organizations.find(
          (organization) => organization._id === currentOrganizationId
        );

        return { organizations, currentOrganization };
      }
    }),
    getPatientHCCs: builder.query({
      query: ({ patientId, searchString }) =>
        `/patient/${patientId}/hcc${searchString}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    updateSourceFeedback: builder.mutation({
      query: ({ sourceId, agree, reason }) => ({
        url: `patient/source/${sourceId}/feedback`,
        method: 'POST',
        body: {
          agree,
          reason
        }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body).success || false;
      }
    }),
    searchIcds: builder.query({
      query: ({ searchValue, cmsVersion }) =>
        `/icds?q=${searchValue}&cmsVersion=${cmsVersion}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    addPatientIcd: builder.mutation({
      query: ({ patientId, body }) => ({
        url: `patient/${patientId}/add-icd`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),

    addSourceToPatientHcc: builder.mutation({
      query: ({ body }) => ({
        url: `/addSourceToPatientHcc`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    generatePatientSummary: builder.query({
      query: (patientId) => `generatePatientSummary/${patientId}`,
      transformResponse: (response) => {
        console.log('[generatePatientSummary] response: ', response);
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).summaryText;
      }
    }),
    getIsUserHalBenchmarkOrg: builder.query({
      query: (userId) => `/getIsUserHalBenchmarkOrg/${userId}`,
      transformResponse: (response) => {
        console.log('[getIsUserHalBenchmarkOrg] response: ', response);
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body);
      }
    }),
    addUserClinicConsent: builder.mutation({
      query: ({ body }) => ({
        url: 'userClinicConsent',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    })
  })
});

// Export hooks for usage in functional components
export const {
  useLazyGetUserQuery,
  useUpdateSourceFeedbackMutation,
  useGetOrganizationsQuery,
  useGetPatientHCCsQuery,
  useLazyGetPatientHCCsQuery,
  useLazySearchIcdsQuery,
  useAddPatientIcdMutation,
  useAddSourceToPatientHccMutation,
  useLazyGeneratePatientSummaryQuery,
  useGetIsUserHalBenchmarkOrgQuery,
  useLazyGetIsUserHalBenchmarkOrgQuery,
  useAddUserClinicConsentMutation
} = apiSlice;

// addSourceToPatientHcc
