import React from 'react';

const LogoComponent = () => {
  return (
    <div className="flex items-center">
      <svg height="50" viewBox="0 0 252 60">
        <use xlinkHref="/assets/sprite.svg#logo1"></use>
      </svg>
    </div>
  );
};

export default LogoComponent;
