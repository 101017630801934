// @flow

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import MDPBackend from 'services/MDPBackend';
import { ForwardRef } from 'components';
import { useAddSourceToPatientHccMutation } from 'api/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  debounce,
  Typography,
  Switch,
  FormControlLabel,
  Divider
} from '@mui/material';
import styled from 'styled-components';

const annotations = [
  'Medication',
  'Lab Value',
  'Diagnosis',
  'Test',
  'Mention',
  'Procedures',
  'SDoH'
];

const frozenAnnotations = ['Medication', 'Mention'];
const operators = ['=', '>', '>=', '<', '<='];
const defaultSource = {
  factName: '',
  annotation: '',
  measure: '',
  operator: '',
  factValue: ''
};

type AddSourceModalProps = {
  open: Boolean,
  classes: Object,
  hccId: Array,
  patientId: String,
  handleClose: () => void
};

const AddSourceModal = (props: AddSourceModalProps) => {
  const currentOrganization = useSelector((state) => state.currentOrganization);
  const dispatch = useDispatch();
  const { open, handleClose, classes, hccId, patientId } = props;

  const [searching, setSearching] = useState(false);

  const [isSemanticSearch, setIsSemanticSearch] = useState(true);

  const [sourcesInput, setSourcesInput] = useState(defaultSource);
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [evidencePairsArray, setEvidencePairsArray] = useState([]);
  const [hideInputs, setHideInputs] = useState(false);
  const [clickedSource, setClickedSource] = useState(null);

  const isFrozen = useMemo(
    () => frozenAnnotations.includes(sourcesInput.annotation) || false,
    [sourcesInput]
  );

  const [addSourceToPatientHcc, { isLoading }] =
    useAddSourceToPatientHccMutation();

  const resetInputValues = () => {
    setClickedSource(null);
    setSearchValue(null);
    setSuggestions(null);
    setSourcesInput(defaultSource);
    setHideInputs(true);
  };

  const handleCloseAddSourceDialog = () => {
    setClickedSource(null);
    setSuggestions(null);
    setSearchValue(null);
    setSourcesInput(defaultSource);
    setHideInputs(false);
    setEvidencePairsArray([]);
    handleClose();
  };

  const handleSearchInput = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    if (value.trim() === '') {
      setSuggestions([]);
      setClickedSource(null);
      return;
    }

    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce(async (value) => {
      if (!value) {
        console.log('Search field is empty, not searching.');
        setSearching(false);
        setSearchValue('');
        setSuggestions([]);
        setClickedSource(null);
        return;
      }

      console.log('Searching for: ', value);

      const searchProvider = isSemanticSearch ? 'HAL' : 'OpenSearch';

      console.log('Search Provider is: ', searchProvider);

      setSearching(true);
      try {
        const response = await MDPBackend.searchPatientDocuments(
          patientId,
          value,
          searchProvider
        );

        console.log('[searchPatientDocuments] response: ', response);

        const data = JSON.parse(response.data.body).data;
        console.log('[searchPatientDocuments] data: ', data);

        const total = {
          totalMatches: data.totalMatches ? data.totalMatches : 0,
          totalFiles: data.totalFiles ? data.totalFiles : 0
        };

        const suggestions = data.suggestions ? data.suggestions : [];

        let pageContentsArray;

        if (searchProvider === 'OpenSearch') {
          const removeHtmlTags = (text) => {
            return text.replace(/<[^>]*>/g, '');
          };

          const extractMappedFields = (responses) => {
            const seenPageContents = new Set(); // Set to track unique pageContents
            return responses.flatMap((response) => {
              return Object.entries(response.highlights).flatMap(
                ([section, excerpts]) => {
                  return excerpts
                    .map((excerpt) => {
                      const cleanedExcerpt = removeHtmlTags(excerpt);

                      // Check if the cleanedExcerpt has already been added
                      if (!seenPageContents.has(cleanedExcerpt)) {
                        seenPageContents.add(cleanedExcerpt);
                        return {
                          contentType: response.contentType,
                          fileHash: response.fileHash,
                          fileName: response.fileName,
                          section: section,
                          pageContent: cleanedExcerpt // Cleaned excerpt with HTML tags removed
                        };
                      }
                      return null;
                    })
                    .filter(Boolean); // Filter out null values (duplicates)
                }
              );
            });
          };

          const mappedSuggestions = extractMappedFields(suggestions);
          console.log('MAPPED SUGGESTIONS from Search: ', mappedSuggestions);
          pageContentsArray = mappedSuggestions;
        } else {
          pageContentsArray = suggestions.flatMap(
            (suggestion) =>
              suggestion.highlights?.['Page Content'].map((content) => ({
                pageContent: content,
                contentType: suggestion.contentType,
                fileName: suggestion.fileName,
                fileHash: suggestion.fileHash
              })) || []
          );
        }

        setSuggestions(pageContentsArray);

        const isSelectedOptionValid = pageContentsArray.some(
          (option) => option.pageContent === clickedSource?.pageContent
        );
        if (!isSelectedOptionValid) {
          setClickedSource(null);
        }
      } catch (error) {
        console.log('[searchPatientDocuments] error: ', error);
      } finally {
        setSearching(false);
      }
    }, 1000),
    [isSemanticSearch]
  );

  const handleSelectSource = (event, value) => {
    const selectedSource = suggestions.find(
      (option) => option.pageContent === event.target.value
    );
    setClickedSource(selectedSource);
  };

  useEffect(() => {
    if (evidencePairsArray.length === 0) {
      setHideInputs(false);
    }
  }, [evidencePairsArray]);

  const handleSearchTypeToggle = (event) => {
    setIsSemanticSearch(event.target.checked);
  };

  const createFilePath = (fileId, fileName, section, searchString) => {
    if (!fileId) return '';

    const cleanSearchString = searchString
      ? searchString.replace(/(\<em\>|\<\/em\>)/g, '')
      : '';

    const encodedSearchString = encodeURIComponent(cleanSearchString);
    const encodedFileName = fileName ? encodeURIComponent(fileName) : '';

    // eslint-disable-next-line max-len
    let path = `/viewer/${patientId}?f=${fileId}&q=${encodedSearchString}&title=${encodedFileName}`;

    return path;
  };

  const getNewEvidenceObject = (evidence) => {
    return {
      patientId: patientId,
      patientHccId: hccId,
      organizationId: currentOrganization._id,
      listOfEvidence: evidencePairsArray,
      nlpSource: 'BENCHMARK'
    };
  };

  const handleSubmit = async () => {
    const body = {
      evidence: getNewEvidenceObject()
    };

    console.log('Body to submit: ', body);

    try {
      const response = await addSourceToPatientHcc({
        body: body
      }).unwrap();

      if (response.success) {
        dispatch(
          showToastMsg({
            open: true,
            message: 'Sources added successfully',
            level: 'success',
            duration: 5000
          })
        );

        handleClose();
      } else {
        dispatch(
          showToastMsg({
            open: true,
            message: 'Something went wrong!',
            level: 'error',
            duration: 5000
          })
        );
      }
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error?.message ?? 'Adding source failed',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      handleCloseAddSourceDialog();
    }
  };

  // ------------- EVIDENCE ---------------- //

  const handleAddMoreEvidence = () => {
    setHideInputs(false);
  };

  const handleClinicalIndicatorChange = (event) => {
    const { name, value } = event.target;
    const obj = { [name]: value };

    if (name === 'annotation' && frozenAnnotations.includes(value)) {
      obj.operator = '=';
    }

    setSourcesInput((prev) => ({
      ...prev,
      ...obj
    }));
  };

  const saveEvidencePair = () => {
    const clinicalIndicator = {
      type: sourcesInput.annotation,
      name: sourcesInput.factName,
      measure: sourcesInput.measure,
      operator: sourcesInput.operator,
      value: sourcesInput.factValue
    };

    const excerpt = {
      fileName: clickedSource.fileName,
      pageNb: clickedSource.page ?? null,
      sectionTitle: clickedSource.section ?? null,
      hash: clickedSource.fileHash,
      contentType: clickedSource.contentType,
      excerpt: clickedSource.pageContent
    };

    const evidencePair = {
      clinicalIndicator: clinicalIndicator,
      excerpt: excerpt
    };

    console.log('Evidence Pair Saved: ', evidencePair);
    resetInputValues();
    setEvidencePairsArray((prev) => [...prev, evidencePair]);
  };

  const handleDeleteExcerpt = () => {
    setClickedSource(null);
  };

  const handleEditEvidence = (index) => {
    const evidenceToEdit = evidencePairsArray[index];

    console.log('Evidence to edit: ', evidenceToEdit);

    setSourcesInput({
      factName: evidenceToEdit.clinicalIndicator.name,
      annotation: evidenceToEdit.clinicalIndicator.type,
      measure: evidenceToEdit.clinicalIndicator.measure,
      operator: evidenceToEdit.clinicalIndicator.operator,
      factValue: evidenceToEdit.clinicalIndicator.value
    });

    setClickedSource({
      fileHash: evidenceToEdit.excerpt.hash,
      fileName: evidenceToEdit.excerpt.fileName,
      pageContent: evidenceToEdit.excerpt.excerpt,
      contentType: evidenceToEdit.excerpt.contentType,
      section: evidenceToEdit.excerpt.section ?? null
    });

    setEvidencePairsArray((prev) => prev.filter((_, idx) => idx !== index));

    setHideInputs(false);
  };

  const handleDeleteEvidence = (index) => {
    setEvidencePairsArray((prev) => prev.filter((_, idx) => idx !== index));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className=""
      PaperProps={{
        className:
          '!min-w-[320px] md:!min-w-[768px] lg:!min-w-[1200px] !min-h-[700px] !max-w-full !w-full'
      }}
    >
      <DialogTitle className="!font-bold">Add Evidence</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <div className="mt-3" style={{ marginLeft: '15px' }}>
            {evidencePairsArray.length > 0 && (
              <div
                style={{
                  marginBottom: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                {evidencePairsArray.map((evidence, i) => (
                  <div
                    key={i.toString()}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                      }}
                    >
                      <div
                        style={{
                          width: '40%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '10px'
                        }}
                      >
                        <div>
                          <b>{evidence.clinicalIndicator.type}</b>
                        </div>
                        <div>
                          <b>{evidence.clinicalIndicator.name}</b>
                        </div>
                        <div>
                          <b>{evidence.clinicalIndicator.measure}</b>
                        </div>
                        <div>
                          <b>{evidence.clinicalIndicator.operator}</b>
                        </div>
                        <div>
                          <b>{evidence.clinicalIndicator.value}</b>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'flex'
                        }}
                      >
                        <div>
                          <FontAwesomeIcon
                            style={{ marginRight: 5, color: 'grey' }}
                            icon={solid('quote-left')}
                          />
                        </div>

                        <div>
                          <Typography>{evidence.excerpt.excerpt}</Typography>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          width: '10%'
                        }}
                      >
                        <IconButton onClick={() => handleEditEvidence(i)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteEvidence(i)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </div>
                    </div>

                    <Divider style={{ margin: '20px 0' }} />
                  </div>
                ))}
              </div>
            )}

            {!hideInputs && (
              <Grid container className="!mb-3 !py-1" spacing={2}>
                <Grid item xs={6} sm={4} md={2}>
                  <FormControl className="w-full">
                    <InputLabel variant="outlined" id="period-label">
                      CI Type
                    </InputLabel>
                    <Select
                      labelId="period-label"
                      variant="outlined"
                      name="annotation"
                      className="!w-full"
                      value={sourcesInput.annotation}
                      label="CI Type"
                      onChange={handleClinicalIndicatorChange}
                    >
                      <MenuItem value="">
                        <ListItemText primary="" />
                      </MenuItem>
                      {annotations.map((annotation, idx) => (
                        <MenuItem key={idx} value={annotation}>
                          <ListItemText
                            primary={annotation || ''}
                            className="!leading-none"
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    label="CI Name"
                    name="factName"
                    variant="outlined"
                    className="!leading-none"
                    disabled={isFrozen}
                    value={sourcesInput.factName}
                    onChange={handleClinicalIndicatorChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    label="Unit"
                    name="measure"
                    variant="outlined"
                    disabled={isFrozen}
                    value={sourcesInput.measure}
                    onChange={handleClinicalIndicatorChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <FormControl className="w-full !h-full">
                    <InputLabel variant="outlined" id="period-label">
                      Operator
                    </InputLabel>
                    <Select
                      labelId="period-label"
                      variant="outlined"
                      name="operator"
                      className="!w-full !h-full"
                      disabled={isFrozen}
                      value={sourcesInput.operator}
                      label="Operator"
                      onChange={handleClinicalIndicatorChange}
                    >
                      <MenuItem value="">
                        <ListItemText primary="" />
                      </MenuItem>
                      {operators.map((operator, idx) => (
                        <MenuItem key={idx} value={operator}>
                          <ListItemText
                            primary={operator || ''}
                            className="!leading-none"
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    label="Value"
                    name="factValue"
                    variant="outlined"
                    value={sourcesInput.factValue}
                    onChange={handleClinicalIndicatorChange}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    label="Search Excerpt"
                    variant="outlined"
                    fullWidth
                    disabled={searching}
                    value={searchValue || ''}
                    onChange={handleSearchInput}
                    InputProps={{
                      endAdornment: (
                        <>{searching && <CircularProgress size={20} />}</>
                      )
                    }}
                  />

                  {suggestions?.length > 0 && (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: '10px' }}
                    >
                      <InputLabel>Sources</InputLabel>
                      <Select
                        label="Sources"
                        value={clickedSource?.pageContent || ''}
                        onChange={handleSelectSource}
                      >
                        {suggestions.map((option) => (
                          <MenuItem
                            key={`${option.fileHash}-${option.pageContent}`}
                            value={option.pageContent}
                          >
                            {option.section
                              ? `${option.section} | ${option.pageContent}`
                              : `${option.pageContent}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <FormControlLabel
                    control={
                      <Switch
                        checked={isSemanticSearch}
                        onChange={handleSearchTypeToggle}
                        name={`switch-search`}
                        color="primary"
                        sx={{
                          marginLeft: '5px',
                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: 'green'
                          },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                            {
                              backgroundColor: 'green'
                            },
                          '& .MuiSwitch-switchBase': {
                            color: 'primary.main'
                          },
                          '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                            backgroundColor: 'primary.main'
                          }
                        }}
                      />
                    }
                    label={
                      isSemanticSearch ? 'Semantic Search' : 'Simple Search'
                    }
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>

        {clickedSource && (
          <div className={classes.sourcesSection}>
            <Button
              variant="outlined"
              className={classes.externalLinkBtn}
              onClick={() => {
                window.open(
                  createFilePath(
                    clickedSource.fileHash,
                    clickedSource.fileName
                  ),
                  '_blank'
                );
              }}
              target="_blank"
            >
              {clickedSource.fileName}
              <ForwardRef>
                <FontAwesomeIcon
                  icon={solid('external-link')}
                  className={classes.icon}
                />
              </ForwardRef>
            </Button>

            <div className={classes.sourceCard}>
              <Grid container className="!w-full">
                <Grid item sm={12} md={10} className="!w-full">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '15px'
                    }}
                  ></div>

                  {clickedSource.pageContent ? (
                    <div className={classes.excerpt}>
                      <FontAwesomeIcon
                        style={{ marginRight: 5, color: 'grey' }}
                        icon={solid('quote-left')}
                      />
                      <Typography>{clickedSource.pageContent}</Typography>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                marginBottom: '15px'
              }}
            >
              {clickedSource !== null && sourcesInput !== null ? (
                <Grid item xs={6} sm={6} md={8}>
                  <div className="flex">
                    <Button
                      variant="outlined"
                      className={classes.saveEvidenceBtn}
                      onClick={() => {
                        saveEvidencePair();
                      }}
                    >
                      Save Evidence
                    </Button>
                  </div>
                </Grid>
              ) : null}
            </div>
          </div>
        )}
        {evidencePairsArray.length > 0 && hideInputs && (
          <Button
            style={{
              marginTop: '15px'
            }}
            variant="contained"
            color="inherit"
            className="!bg-[#7bbff8]"
            onClick={handleAddMoreEvidence}
          >
            Add More Evidence
          </Button>
        )}
      </DialogContent>
      <DialogActions className="flex !justify-end flex-row-reverse">
        <Button
          className=" !py-1 !text-black !text-[13px]"
          onClick={handleCloseAddSourceDialog}
        >
          Cancel
        </Button>
        <Button
          className="!py-1 !text-black !text-[13px]"
          sx={{
            ':not(:disabled)': {
              backgroundColor: 'rgba(76, 175, 80, 0.4)',
              '&:hover': {
                backgroundColor: 'rgba(76, 175, 80, 0.4)'
              }
            }
          }}
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || evidencePairsArray.length === 0}
        >
          {isLoading ? 'Adding' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSourceModal;
