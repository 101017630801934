// @flow

import React, { useState, Suspense } from 'react';
import { Auth } from 'aws-amplify';
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import CircularProgress from '@mui/material/CircularProgress';
import { HOME, LOGIN } from 'settings/constants';
import InvalidUrl from './InvalidUrl';
import PrivateRoute from './PrivateRoute';
import routes from './Routes';
import HCCPage from 'containers/Patient/pages/HCC';
import FileBrowserPage from 'containers/Patient/pages/FileBrowser';
import { useSelector } from 'react-redux';

const RouterApp = (): React.Node => {
  const [forceLogout, setForceLogout] = useState(false);
  const { user } = useSelector((state) => state.user);

  const onIdle = () => {
    Auth.signOut({ global: true }).then(() => {
      setForceLogout(true);
    });
  };

  const timeoutDuration = 1000 * 60 * 30; // 30 minutes

  useIdleTimer({
    timeout: timeoutDuration,
    onIdle,
    crossTab: true
  });

  return (
    <Router>
      <Suspense fallback={<CircularProgress />}>
        <Routes>
          <Route path="*" element={<InvalidUrl />} />
          {routes.map((route, i) => {
            const Component = route.component;

            return route.private ? (
              route.path === '/patient/:patientId' ? (
                <Route
                  key={i}
                  exact={route.exact}
                  path={route.path}
                  element={
                    <PrivateRoute
                      component={route.component}
                      forceLogout={forceLogout}
                    />
                  }
                >
                  <Route exact path="" element={<HCCPage />} />
                  {user?.role !== 'payer' && (
                    <Route exact path="files" element={<FileBrowserPage />} />
                  )}
                </Route>
              ) : (
                <Route
                  key={i}
                  exact={route.exact}
                  path={route.path}
                  element={
                    <PrivateRoute
                      component={route.component}
                      forceLogout={forceLogout}
                    />
                  }
                />
              )
            ) : (
              <Route
                element={<Component />}
                exact={route.exact}
                path={route.path}
                key={i}
              />
            );
          })}
          <Route exact path={HOME} element={<Navigate to={LOGIN} />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RouterApp;
