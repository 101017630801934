// @flow

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import * as R from 'ramda';
import { withStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import enLocale from 'date-fns/locale/en-US';
import PageTemplate from 'layout/PageTemplate';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';
import EventsTable from './EventsTable';
import styles from './Events.Style';

const dates = {
  all: {
    from: '',
    to: ''
  },
  today: {
    from: format(new Date(), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  thisWeek: {
    from: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  thisMonth: {
    from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    to: format(endOfMonth(new Date()), 'yyyy-MM-dd')
  }
};

const searchPeriods = [
  {
    name: 'All',
    value: 'all'
  },
  {
    name: 'Today',
    value: 'today'
  },
  {
    name: 'This week',
    value: 'thisWeek'
  },
  {
    name: 'This month',
    value: 'thisMonth'
  }
];

const getDatesBasedOnPeriodName = (periodName) => {
  const selectedPeriod = R.find(R.propEq('name', periodName))(searchPeriods);

  if (selectedPeriod?.value) {
    const from = dates[selectedPeriod.value].from;
    const to = dates[selectedPeriod.value].to;

    return {
      periodName,
      from,
      to
    };
  }

  return {};
};

const DEFAULT_PERIOD_NAME = 'Today';

const DEFAULT_SEARCH_PERIOD = {
  periodName: DEFAULT_PERIOD_NAME,
  from: getDatesBasedOnPeriodName(DEFAULT_PERIOD_NAME).from,
  to: getDatesBasedOnPeriodName(DEFAULT_PERIOD_NAME).to
};

const DEFAULT_EVENT_FILTERS = {
  ...DEFAULT_SEARCH_PERIOD,
  page: 1,
  perPage: 50
};

type Props = {
  classes: Object
};

const Events = (props: Props) => {
  const { classes } = props;
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [events, setEvents] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [eventTypes, setEventTypes] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = queryString.parse(location.search);

  const [eventFilters, setEventFilters] = useState(
    Object.keys(urlParams).length > 0
      ? { ...DEFAULT_EVENT_FILTERS, ...urlParams }
      : DEFAULT_EVENT_FILTERS
  );

  const setUrlQueryParams = (params) => {
    const currentParams = queryString.parse(location.search);

    const searchString = new URLSearchParams({
      ...currentParams,
      ...params
    }).toString();

    console.log('[setUrlQueryParams] searchString: ', searchString);

    navigate(
      {
        search: searchString
      },
      { replace: true }
    );
  };

  const resetSearchParams = () => {
    setEventFilters(DEFAULT_EVENT_FILTERS);
    navigate({
      search: ''
    });
  };

  const getAllEvents = async (params) => {
    log('[getAllEvents] params: ', params);

    const searchString = new URLSearchParams(params);

    setLoading(true);

    try {
      const res = await MDPBackend.getAllEvents(searchString);
      log('[getAllEvents] res: ', res);

      const parsedResponse = JSON.parse(res.data.body);

      const data = parsedResponse.data;
      setEventTypes(parsedResponse.eventTypes);
      setTotalRows(parsedResponse.total);
      setEvents(data);
    } catch (error) {
      log('[getAllEvents] error: ', error);
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const onEventTypeChange = (event) => {
    const newFilters = {
      ...eventFilters,
      eventType: event.target.value
    };

    setEventFilters(newFilters);
    setUrlQueryParams(newFilters);
  };

  const onEventFromDatePickerChange = (value) => {
    console.log('[onEventFromDatePickerChange]... value: ', value);

    const from = format(value, 'yyyy-MM-dd');

    const newFilters = {
      ...eventFilters,
      from: from,
      periodName: '',
      page: 1,
      perPage: 50
    };

    setEventFilters(newFilters);
    setUrlQueryParams(newFilters);
  };

  const onEventToDatePickerChange = (value) => {
    const to = format(value, 'yyyy-MM-dd');

    const newFilters = {
      ...eventFilters,
      to: to,
      periodName: '',
      page: 1,
      perPage: 50
    };

    setEventFilters(newFilters);
    setUrlQueryParams(newFilters);
  };

  const setDatesBasedOnPeriodName = async (periodName) => {
    const period = getDatesBasedOnPeriodName(periodName);

    const newFilters = {
      ...eventFilters,
      ...period
    };

    setEventFilters(newFilters);
    setUrlQueryParams(newFilters);
  };

  const onCreatedInputChange = (e) => {
    setDatesBasedOnPeriodName(e.target.value);
  };

  useEffect(() => {
    getAllEvents(eventFilters);
  }, [eventFilters]);

  return (
    <>
      <PageTemplate>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Events</title>
        </Helmet>
        <Container maxWidth={false}>
          <div className={classes.paperRoot}>
            <Grid container spacing={2}>
              <Grid item sm={6} md={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enLocale}
                >
                  <DatePicker
                    label={'Event from'}
                    value={
                      eventFilters.from ? parseISO(eventFilters.from) : null
                    }
                    onChange={onEventFromDatePickerChange}
                    renderInput={(inputParams) => (
                      <TextField
                        {...inputParams}
                        type="outlined"
                        className="!w-full"
                        error={false}
                      />
                    )}
                    disabled={loading}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sm={6} md={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enLocale}
                >
                  <DatePicker
                    label="Event to"
                    value={eventFilters.to ? parseISO(eventFilters.to) : null}
                    onChange={onEventToDatePickerChange}
                    renderInput={(inputParams) => (
                      <TextField
                        {...inputParams}
                        type="outlined"
                        className="!w-full"
                        error={false}
                      />
                    )}
                    disabled={loading}
                    // inputFormat="yyyy-MM-dd"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sm={6} md={3}>
                <FormControl disabled={loading} className="!w-full !min-w-full">
                  <InputLabel variant="outlined" id="period-label">
                    Events within
                  </InputLabel>
                  <Select
                    labelId="period-label"
                    variant="outlined"
                    id="search"
                    value={eventFilters.periodName || ''}
                    onChange={onCreatedInputChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="period-label"
                      />
                    }
                  >
                    {searchPeriods.map((period, idx) => (
                      <MenuItem key={idx} value={period.name}>
                        {period.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={6} md={3}>
                <TextField
                  className="w-full !min-w-full"
                  name={name}
                  select
                  value={eventFilters.eventType || ''}
                  label="Event Type"
                  onChange={onEventTypeChange}
                  disabled={eventTypes.length < 1}
                >
                  {eventTypes.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={6} md={4}></Grid>
            </Grid>
            <Button onClick={() => resetSearchParams()} disabled={loading}>
              Reset Filters
            </Button>
            {initialLoading ? (
              <div className={classes.loadingCentered}>
                <CircularProgress />
              </div>
            ) : (
              <EventsTable
                events={events}
                loading={loading}
                setUrlQueryParams={setUrlQueryParams}
                eventFilters={eventFilters}
                setEventFilters={setEventFilters}
                totalRows={totalRows}
              />
            )}
          </div>
        </Container>
      </PageTemplate>
    </>
  );
};

export default withStyles(Events, styles);
