// @flow

import React, { useEffect, useState, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddSourceDialogContent from './AddSourceDialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import MDPBackend from 'services/MDPBackend';
import {
  useAddPatientIcdMutation,
  useLazySearchIcdsQuery,
  useAddSourceToPatientHccMutation
} from 'api/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  debounce,
  Divider,
  Switch,
  FormControlLabel
} from '@mui/material';
import { DEFAULT_HCC_VERSION } from '../PatientHCCs';

const annotations = [
  'Medication',
  'Lab Value',
  'Diagnosis',
  'Test',
  'Mention',
  'Procedures',
  'SDoH'
];
const operators = ['=', '>', '>=', '<', '<='];
const defaultSource = {
  factName: '',
  annotation: '',
  measure: '',
  operator: '',
  factValue: ''
};

type AddIcdModalProps = {
  open: Boolean,
  currentIcdCodes: Array,
  cmsVersion: String,
  patientId: String,
  patientHccRefetch: Function,
  handleClose: () => void,
  nlpSource: String,
  classes: Object
};

const AddIcdModal = (props: AddIcdModalProps) => {
  const {
    open,
    handleClose,
    currentIcdCodes,
    patientId,
    cmsVersion,
    patientHccRefetch,
    nlpSource,
    classes
  } = props;
  const [searchIcds, { data: options, isLoading: loading }] =
    useLazySearchIcdsQuery();
  const [addPatientIcd, { isLoading }] = useAddPatientIcdMutation();
  const [addSourceToPatientHcc, { isLoadingAddSource }] =
    useAddSourceToPatientHccMutation();
  const dispatch = useDispatch();
  const currentOrganization = useSelector((state) => state.currentOrganization);

  const [searching, setSearching] = useState(false);
  const [body, setBody] = useState(null);
  const [clickedIcdCode, setClickedIcdCode] = useState(null);
  const [clickedSource, setClickedSource] = useState(null);
  const [showAddEvidence, setShowAddEvidence] = useState(false);
  const [sourcesInput, setSourcesInput] = useState(defaultSource);
  const [searchValue, setSearchValue] = useState('');
  const [excerptSearchValue, setExcerptSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [initEvidence, setInitEvidence] = useState(false);
  const [evidencePairsArray, setEvidencePairsArray] = useState([]);
  const [hideInputs, setHideInputs] = useState(true);
  const [isSemanticSearch, setIsSemanticSearch] = useState(true);
  const [searchingForExcerpt, setSearchingForExcerpt] = useState(false);

  const delayedIcdSearch = debounce(async (value) => {
    setSearching(true);
    await searchIcds({ searchValue: value, cmsVersion });
    setSearching(false);
  }, 300);

  useEffect(() => {
    if (evidencePairsArray.length === 0) {
      setHideInputs(false);
    }
  }, [evidencePairsArray]);

  const resetInputValues = () => {
    setClickedSource(null);
    setSuggestions(null);
    setSearchValue(null);
    setExcerptSearchValue(null);
    setSourcesInput(defaultSource);
    setHideInputs(true);
  };

  const handleCloseDialog = () => {
    setClickedSource(null);
    setClickedIcdCode(null);
    setSuggestions(null);
    setSearchValue(null);
    setExcerptSearchValue(null);
    setSourcesInput(defaultSource);
    setHideInputs(true);
    setEvidencePairsArray([]);
    setBody(null);
    handleClose();
  };

  // Handler for input change
  const handleSearch = (event, value) => {
    delayedIcdSearch(value);
  };

  const handleAddIcd = (event, value) => {
    if (value) {
      const {
        icdCode,
        icdCodeDescription,
        hccCategory,
        hccCategoryDescription,
        cmsVersion
      } = value;

      setClickedIcdCode(icdCode);
      setHideInputs(false);

      setBody((prev) => ({
        ...prev,
        icdCode,
        icdCodeDescription,
        hccCategory,
        hccCategoryDescription,
        cmsVersion: cmsVersion || DEFAULT_HCC_VERSION,
        actionPayload: { status: 'identified' },
        nlpSource
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      body.sources = evidencePairsArray;

      console.log('Body to send: ', body);

      const response = await addPatientIcd({
        patientId,
        body
      }).unwrap();

      if (response.success) {
        dispatch(
          showToastMsg({
            open: true,
            message: 'ICD added successfully',
            level: 'success',
            duration: 5000
          })
        );
        patientHccRefetch();
        handleCloseDialog();
      } else {
        throw new Error(response.message || response);
      }
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error?.message ?? 'adding failed',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  const handleSearchTypeToggle = (event) => {
    setIsSemanticSearch(event.target.checked);
  };

  const handleClinicalIndicatorChange = (event) => {
    const { name, value } = event.target;
    setSourcesInput((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSearchInput = (event) => {
    const value = event.target.value;
    setExcerptSearchValue(value);

    if (value.trim() === '') {
      setSuggestions([]);
      setClickedSource(null);
      return;
    }

    debouncedExcerptSearch(value);
  };

  const debouncedExcerptSearch = useCallback(
    debounce(async (value) => {
      if (!value) {
        console.log('Search field is empty, not searching.');
        setSearchingForExcerpt(false);
        setExcerptSearchValue('');
        setSuggestions([]);
        setClickedSource(null);
        return;
      }

      console.log('Searching for: ', value);

      const searchProvider = isSemanticSearch ? 'HAL' : 'OpenSearch';

      console.log('Search Provider is: ', searchProvider);

      setSearchingForExcerpt(true);
      try {
        const response = await MDPBackend.searchPatientDocuments(
          patientId,
          value,
          searchProvider
        );

        console.log('[searchPatientDocuments] response: ', response);

        const data = JSON.parse(response.data.body).data;
        console.log('[searchPatientDocuments] data: ', data);

        const total = {
          totalMatches: data.totalMatches ? data.totalMatches : 0,
          totalFiles: data.totalFiles ? data.totalFiles : 0
        };

        const suggestions = data.suggestions ? data.suggestions : [];
        let pageContentsArray;

        if (searchProvider === 'OpenSearch') {
          console.log('Suggestions Open Search: ', suggestions);

          const removeHtmlTags = (text) => {
            return text.replace(/<[^>]*>/g, '');
          };

          const extractMappedFields = (responses) => {
            const seenPageContents = new Set(); // Set to track unique pageContents
            return responses.flatMap((response) => {
              return Object.entries(response.highlights).flatMap(
                ([section, excerpts]) => {
                  return excerpts
                    .map((excerpt) => {
                      const cleanedExcerpt = removeHtmlTags(excerpt);

                      // Check if the cleanedExcerpt has already been added
                      if (!seenPageContents.has(cleanedExcerpt)) {
                        seenPageContents.add(cleanedExcerpt);
                        return {
                          contentType: response.contentType,
                          fileHash: response.fileHash,
                          fileName: response.fileName,
                          section: section,
                          pageContent: cleanedExcerpt // Cleaned excerpt with HTML tags removed
                        };
                      }
                      return null;
                    })
                    .filter(Boolean); // Filter out null values (duplicates)
                }
              );
            });
          };

          const mappedSuggestions = extractMappedFields(suggestions);
          pageContentsArray = mappedSuggestions;
        } else {
          pageContentsArray = suggestions.flatMap(
            (suggestion) =>
              suggestion.highlights?.['Page Content'].map((content) => ({
                pageContent: content,
                contentType: suggestion.contentType,
                fileName: suggestion.fileName,
                fileHash: suggestion.fileHash
              })) || []
          );
        }

        setSuggestions(pageContentsArray);

        const isSelectedOptionValid = pageContentsArray.some(
          (option) => option.pageContent === clickedSource?.pageContent
        );
        if (!isSelectedOptionValid) {
          setClickedSource(null);
        }
      } catch (error) {
        console.log('[searchPatientDocuments] error: ', error);
      } finally {
        setSearchingForExcerpt(false);
      }
    }, 1000),
    [isSemanticSearch]
  );

  const handleSelectSource = (event, value) => {
    const selectedSource = suggestions.find(
      (option) => option.pageContent === event.target.value
    );
    setClickedSource(selectedSource);
  };

  const createFilePath = (fileId, fileName, section, searchString) => {
    if (!fileId) return '';

    const cleanSearchString = searchString
      ? searchString.replace(/(\<em\>|\<\/em\>)/g, '')
      : '';

    const encodedSearchString = encodeURIComponent(cleanSearchString);
    const encodedFileName = fileName ? encodeURIComponent(fileName) : '';

    // eslint-disable-next-line max-len
    let path = `/viewer/${patientId}?f=${fileId}&q=${encodedSearchString}&title=${encodedFileName}`;

    return path;
  };

  const saveEvidencePair = () => {
    const clinicalIndicator = {
      type: sourcesInput.annotation,
      name: sourcesInput.factName,
      measure: sourcesInput.measure,
      operator: sourcesInput.operator,
      value: sourcesInput.factValue
    };

    const excerpt = {
      fileName: clickedSource.fileName,
      pageNb: clickedSource.page ?? null,
      sectionTitle: clickedSource.section ?? null,
      hash: clickedSource.fileHash,
      contentType: clickedSource.contentType,
      excerpt: clickedSource.pageContent
    };

    const evidencePair = {
      clinicalIndicator: clinicalIndicator,
      excerpt: excerpt
    };

    console.log('Evidence Pair Saved: ', evidencePair);
    resetInputValues();
    setEvidencePairsArray((prev) => [...prev, evidencePair]);
  };

  const handleAddMoreEvidence = () => {
    setHideInputs(false);
  };

  const handleAddEvidence = () => {
    setHideInputs(false);
  };

  const handleEditEvidence = (index) => {
    const evidenceToEdit = evidencePairsArray[index];

    console.log('Evidence to edit: ', evidenceToEdit);

    setSourcesInput({
      factName: evidenceToEdit.clinicalIndicator.name,
      annotation: evidenceToEdit.clinicalIndicator.type,
      measure: evidenceToEdit.clinicalIndicator.measure,
      operator: evidenceToEdit.clinicalIndicator.operator,
      factValue: evidenceToEdit.clinicalIndicator.value
    });

    setClickedSource({
      fileHash: evidenceToEdit.excerpt.hash,
      fileName: evidenceToEdit.excerpt.fileName,
      pageContent: evidenceToEdit.excerpt.excerpt,
      contentType: evidenceToEdit.excerpt.contentType,
      section: evidenceToEdit.excerpt.section ?? null
    });

    setEvidencePairsArray((prev) => prev.filter((_, idx) => idx !== index));

    setHideInputs(false);
  };

  const handleDeleteEvidence = (index) => {
    setEvidencePairsArray((prev) => prev.filter((_, idx) => idx !== index));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className=""
      PaperProps={{
        className: '!min-w-[320px] md:!min-w-[1200px] !min-h-[700px]'
      }}
    >
      <DialogTitle className="!font-bold">Add New ICD</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Autocomplete
              style={{ marginTop: '10px' }}
              options={options || []}
              getOptionLabel={(option) =>
                `${option.icdCode} - ${option.icdCodeDescription} ${
                  option.hccCategory ? `-- HCC: ${option.hccCategory}` : ''
                } ${
                  option.hccCategoryDescription
                    ? ` (${option.hccCategoryDescription})`
                    : ''
                }`
              }
              isOptionDisabled={(option) => {
                return currentIcdCodes.includes(option?.icdCode);
              }}
              onChange={handleAddIcd}
              onInputChange={handleSearch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={loading || searching ? 'Searching...' : 'Search ICD'}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {(loading || searching) && (
                          <CircularProgress size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <div className="mt-3">
          {evidencePairsArray.length > 0 && (
            <div
              style={{
                marginBottom: '15px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              {evidencePairsArray.map((evidence, i) => (
                <div
                  key={i.toString()}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <div
                      style={{
                        width: '40%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px'
                      }}
                    >
                      <div>
                        <b>{evidence.clinicalIndicator.type}</b>
                      </div>
                      <div>
                        <b>{evidence.clinicalIndicator.name}</b>
                      </div>
                      <div>
                        <b>{evidence.clinicalIndicator.measure}</b>
                      </div>
                      <div>
                        <b>{evidence.clinicalIndicator.operator}</b>
                      </div>
                      <div>
                        <b>{evidence.clinicalIndicator.value}</b>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '50%',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'flex'
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          style={{ marginRight: 5, color: 'grey' }}
                          icon={solid('quote-left')}
                        />
                      </div>

                      <div>
                        <Typography>{evidence.excerpt.excerpt}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '10%'
                      }}
                    >
                      <IconButton onClick={() => handleEditEvidence(i)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteEvidence(i)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </div>
                  </div>

                  <Divider style={{ margin: '20px 0' }} />
                </div>
              ))}
            </div>
          )}
          {!hideInputs && clickedIcdCode && (
            <Grid container className="!mb-3" spacing={2}>
              <Grid item xs={6} sm={4} md={2}>
                <FormControl className="w-full">
                  <InputLabel variant="outlined" id="period-label">
                    CI Type
                  </InputLabel>
                  <Select
                    labelId="period-label"
                    variant="outlined"
                    name="annotation"
                    className="!w-full"
                    value={sourcesInput.annotation || ''}
                    label="CI Type"
                    onChange={handleClinicalIndicatorChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="period-label"
                      />
                    }
                  >
                    <MenuItem value="">
                      <ListItemText primary="" />
                    </MenuItem>
                    {annotations.map((annotation, idx) => (
                      <MenuItem key={idx} value={annotation}>
                        <ListItemText
                          primary={annotation || ''}
                          className="!leading-none"
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <TextField
                  label="CI Name"
                  name="factName"
                  variant="outlined"
                  className="!leading-none"
                  value={sourcesInput.factName || ''}
                  onChange={handleClinicalIndicatorChange}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <TextField
                  label="Unit"
                  name="measure"
                  variant="outlined"
                  value={sourcesInput.measure || ''}
                  onChange={handleClinicalIndicatorChange}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <FormControl className="w-full !h-full">
                  <InputLabel variant="outlined" id="period-label">
                    Operator
                  </InputLabel>
                  <Select
                    labelId="period-label"
                    variant="outlined"
                    name="operator"
                    className="!w-full !h-full"
                    value={sourcesInput.operator}
                    label="Operator"
                    onChange={handleClinicalIndicatorChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="period-label"
                      />
                    }
                  >
                    <MenuItem value="">
                      <ListItemText primary="" />
                    </MenuItem>
                    {operators.map((operator, idx) => (
                      <MenuItem key={idx} value={operator}>
                        <ListItemText
                          primary={operator || ''}
                          className="!leading-none"
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <TextField
                  label="Value"
                  name="factValue"
                  variant="outlined"
                  value={sourcesInput.factValue || ''}
                  onChange={handleClinicalIndicatorChange}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="Search Excerpt"
                  variant="outlined"
                  fullWidth
                  disabled={searchingForExcerpt}
                  value={excerptSearchValue || ''}
                  onChange={handleSearchInput}
                  InputProps={{
                    endAdornment: (
                      <>
                        {searchingForExcerpt && <CircularProgress size={20} />}
                      </>
                    )
                  }}
                />

                {suggestions?.length > 0 && (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                  >
                    <InputLabel>Sources</InputLabel>
                    <Select
                      label="Sources"
                      value={clickedSource?.pageContent || ''}
                      onChange={handleSelectSource}
                    >
                      {suggestions.map((option) => (
                        <MenuItem
                          key={`${option.fileHash}-${option.pageContent}`}
                          value={option.pageContent}
                        >
                          {option.section
                            ? `${option.section} | ${option.pageContent}`
                            : `${option.pageContent}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <FormControlLabel
                  control={
                    <Switch
                      checked={isSemanticSearch}
                      onChange={handleSearchTypeToggle}
                      name={`switch-search`}
                      color="primary"
                      sx={{
                        marginLeft: '5px',
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: 'green'
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                          {
                            backgroundColor: 'green'
                          },
                        '& .MuiSwitch-switchBase': {
                          color: 'primary.main'
                        },
                        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                          backgroundColor: 'primary.main'
                        }
                      }}
                    />
                  }
                  label={isSemanticSearch ? 'Semantic Search' : 'Simple Search'}
                />
              </Grid>
            </Grid>
          )}

          {clickedSource && (
            <div className={classes.sourcesSection}>
              <Button
                variant="outlined"
                className={classes.externalLinkBtn}
                onClick={() => {
                  window.open(
                    createFilePath(
                      clickedSource.fileHash,
                      clickedSource.fileName
                    ),
                    '_blank'
                  );
                }}
                target="_blank"
              >
                {clickedSource.fileName}
                <ForwardRef>
                  <FontAwesomeIcon
                    icon={solid('external-link')}
                    className={classes.icon}
                  />
                </ForwardRef>
              </Button>

              <div className={classes.sourceCard}>
                <Grid container className="!w-full">
                  <Grid item sm={12} md={10} className="!w-full">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '15px'
                      }}
                    ></div>

                    {clickedSource.pageContent ? (
                      <div className={classes.excerpt}>
                        <FontAwesomeIcon
                          style={{ marginRight: 5, color: 'grey' }}
                          icon={solid('quote-left')}
                        />
                        <Typography>{clickedSource.pageContent}</Typography>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  marginBottom: '15px'
                }}
              >
                {clickedSource !== null && sourcesInput !== null ? (
                  <Grid item xs={6} sm={6} md={8}>
                    <div className="flex">
                      <Button
                        variant="outlined"
                        className={classes.saveEvidenceBtn}
                        onClick={() => {
                          saveEvidencePair();
                        }}
                      >
                        Save Evidence
                      </Button>
                    </div>
                  </Grid>
                ) : null}
              </div>
            </div>
          )}

          {evidencePairsArray.length > 0 && hideInputs && (
            <Button
              style={{
                marginTop: '15px'
              }}
              variant="contained"
              color="inherit"
              className="!bg-[#7bbff8]"
              onClick={handleAddMoreEvidence}
            >
              Add More Evidence
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogActions className="flex !justify-end flex-row-reverse">
        <Button
          className=" !py-1 !text-black !text-[13px]"
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          className="!py-1 !text-black !text-[13px]"
          sx={{
            ':not(:disabled)': {
              backgroundColor: 'rgba(76, 175, 80, 0.4)',
              '&:hover': {
                backgroundColor: 'rgba(76, 175, 80, 0.4)'
              }
            }
          }}
          variant="contained"
          disabled={isLoading || !body?.hccCategory}
          onClick={handleSubmit}
        >
          Confirm
          {isLoading && <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddIcdModal;
