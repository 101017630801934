// @flow

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Controller } from 'react-hook-form';

import MDPBackend from '../../services/MDPBackend';

type Props = {
  control: Object,
  className: String,
  label: String,
  name: String,
  required: Boolean,
  setValue: () => void
};

const QueryAutoComplete = (props: Props) => {
  const { control, className, label, name, required, setValue } = props;
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const getPatientSuggestions = async () => {
    setLoading(true);
    console.log('[getPatientSuggestions] inputValue:', inputValue);

    try {
      const response = await MDPBackend.getPatientSuggestions(inputValue);
      const _suggestions = JSON.parse(response.data.body).suggestions ?? [];
      console.log('[getPatientSuggestions] _suggestions:', _suggestions);
      setSuggestions(_suggestions);

      console.log('[getPatientSuggestions] suggestions:', suggestions);
    } catch (error) {
      console.log('[getPatientSuggestions] error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestion = (data) => {
    if (!data) return;
    console.log('[handleSuggestion] data: ', data);

    setTimeout(() => {
      if (data.patient_first_name) {
        setValue('patient_first_name', data.patient_first_name, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_last_name) {
        setValue('patient_last_name', data.patient_last_name, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_dob) {
        setValue('patient_dob', data.patient_dob, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_sex) {
        setValue('patient_sex', data.patient_sex, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_org_source_id) {
        setValue('patient_org_source_id', data.patient_org_source_id, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_address) {
        setValue('patient_address', data.patient_address, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_city) {
        setValue('patient_city', data.patient_city, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_zip) {
        setValue('patient_zip', data.patient_zip, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
      if (data.patient_state) {
        setValue('patient_state', data.patient_state, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        });
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => getPatientSuggestions(), 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          {...field}
          /* eslint-disable react/prop-types */
          onChange={(_, data) => {
            handleSuggestion(data);
          }}
          value={field.value}
          options={suggestions}
          getOptionLabel={(option) =>
            typeof option === 'string'
              ? option
              : typeof option !== 'undefined' && option && option[name]
              ? option[name]
              : ''
          }
          freeSolo
          loading={loading}
          includeInputInList
          disablePortal
          filterOptions={(option) => option}
          filterSelectedOptions
          renderOption={(prop, option) => (
            <Grid {...prop} container alignItems="center">
              <Grid item>
                <AccountCircleIcon style={{ marginRight: 10 }} />
              </Grid>
              {option ? (
                <Grid item xs>
                  <Typography style={{ marginBottom: 1 }}>
                    {option.patient_first_name} {option.patient_last_name}
                  </Typography>

                  {option.patient_org_source_id && (
                    <Typography variant="caption">
                      {option.patient_org_source_id}{' '}
                    </Typography>
                  )}
                  {option.patient_dob && (
                    <Typography variant="caption">
                      {option.patient_dob}{' '}
                    </Typography>
                  )}
                  {option.patient_sex && (
                    <Typography variant="caption">
                      {option.patient_sex}{' '}
                    </Typography>
                  )}

                  <br />
                  {option.patient_address && (
                    <Typography variant="caption" color="textSecondary">
                      {option.patient_address}{' '}
                    </Typography>
                  )}
                  {option.patient_city && (
                    <Typography variant="caption" color="textSecondary">
                      {option.patient_city}{' '}
                    </Typography>
                  )}
                  {option.patient_zip && (
                    <Typography variant="caption" color="textSecondary">
                      {option.patient_zip}{' '}
                    </Typography>
                  )}
                  {option.patient_state && (
                    <Typography variant="caption" color="textSecondary">
                      {option.patient_state}{' '}
                    </Typography>
                  )}
                </Grid>
              ) : null}
            </Grid>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label ? label : ''}
              variant="outlined"
              className={className}
              onChange={(e) => {
                setInputValue(e.target.value);
                field.onChange(e.target.value);
              }}
              required={required}
            />
          )}
        />
      )}
      name={name ? name : ''}
      control={control}
      rules={{ required: required ? `${label} is required` : false }}
    />
  );
};

export default QueryAutoComplete;
